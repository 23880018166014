import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public user:any;
  public userInfo:any;

  public get isAdmin():boolean {
    return this.userInfo && this.userInfo.role === 'admin';
  }

  constructor(
    private db:AngularFireDatabase,
    private auth:AngularFireAuth
  ) {
    this.auth.user.subscribe((user)=>{
      this.user = user;
      if (user){
        this.db.object('/users/' + user.uid).snapshotChanges().subscribe(userInfo => {
          this.userInfo = userInfo.payload.val();
          if (!this.userInfo.enabled){
            this.auth.auth.signOut();
          }
        });
      } else {
        this.userInfo = null;
      }
    });
  }

  private addKeyToList(ref:any){
    return ref.snapshotChanges().pipe(
      map((changes:any[]) =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }

  private getList(path:string, params?:any){
    let ref = this.db.list(path, params);
    let list = this.addKeyToList(ref);
    return { ref, list };
  }

  private getObject(path:string){
    let ref = this.db.object(path);
    let obj = ref.snapshotChanges().pipe(map(c => ({ key: c.payload.key, ...c.payload.val() })));
    return { ref, obj };
  }

  public addUserInfo(uid, userInfo){
    userInfo.createdAt = Date.now();
    this.db.object('/users/' + uid).update(userInfo);
  }

  public getUsers() : any {
    return this.getList('/users');
  }

  public saveUser(user:any){
    let userUpdate:any = {
      name: user.name,
      email: user.email,
      enabled: user.enabled,
    };
    if (user.role){
      userUpdate.role = user.role;
    } else {
      this.db.object('/users/'+user.key+'/role').remove();
    }
    if (user.deleted){
      userUpdate.deleted = true;
    }
    return this.db.object('/users/'+user.key).update(userUpdate);
  }

  public getProjects() : any {
    return this.getList('/projects');
  }

  public getProject(id:string) {
    return this.getObject('/projects/'+id);
  }

  public deleteProject(project:any){
    return this.db.object('/projects/'+project.key).remove();
  }

  public saveProject(project:any) : any {
    if (!project.version){
      project.version = 1;
    } else {
      project.version++;
    }
    project.createdAt = Date.now();
    project.designer = this.userInfo;
    if (!project.key){
      delete(project.key);
    }
    return this.db.list('/projects').push(project);
  }

  public getDesignSettings(){
    return this.getObject('/settings');
  }

  public saveDesignSettings(settings:any){
    this.db.object('/settings').set(settings);
  }

  public getDefaultProjectSettings(){
    return this.getObject('/settings/project').obj.pipe(map(obj => ({
      ...obj,
      key: undefined
    })));
  }

}
