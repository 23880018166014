import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';

import { ProjectNameDialogComponent } from '../../dialogs/project-name-dialog/project-name-dialog.component';

//declare const jQuery:any;

@Component({
  selector: 'app-edit-project-page',
  templateUrl: './edit-project-page.component.html',
  styleUrls: ['./edit-project-page.component.scss']
})
export class EditProjectPageComponent implements OnInit {

  @ViewChild('results', {static: false}) results:any;

  public pageTitle:string;
  public project:any;

  public step:number = 1;
  public steps:any[] = [
    { id: 1, name: 'Felépítmény'},
    { id: 2, name: 'Úszómű elem'},
    { id: 3, name: 'Úszómű elem + Felépítmény'},
    { id: 4, name: 'Eredmény'}
  ];

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private data:DataService,
    private dialog:DialogService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      switch (routeParams.projectEditMode){
        case 'copy':
          this.createByCopy(routeParams.id);
          break;
        case 'modify':
          this.createByModifying(routeParams.id);
          break;
        case 'create':
          this.createNew();
          break;
      }
    });
  }

  createByCopy(projectId:string){
    this.pageTitle = 'Új Ponton Terv másolatból';
    this.data.getProject(projectId).obj.subscribe(project => {
      this.project = project;
      this.askProjectName();
      delete(this.project.key);
      delete(this.project.version);
    });
  }

  createByModifying(projectId:string){
    this.pageTitle = 'Ponton Terv módosítása';
    this.data.getProject(projectId).obj.subscribe(project => {
      this.project = project;
      delete(this.project.key);
    });
  }

  createNew(){
    this.pageTitle = 'Új Ponton Terv készítés';
    this.data.getDefaultProjectSettings().subscribe((defaultProjectSettings:any) => {
      this.project = {
        ...defaultProjectSettings
      };
      this.askProjectName();
    })
  }

  askProjectName(){
    this.dialog.showDialog(ProjectNameDialogComponent, {}).then((projectName:string) => {
      this.project.name = projectName;
      if (!projectName){
        this.router.navigate(['/dashboard']);
      }
    }).catch(() => {
      this.router.navigate(['/dashboard']);
    })
  }

  prev(){
    if (this.step > 1){
      this.step--;
    }
  }

  next(){
    if (this.step < this.steps.length){
      this.step++;
    }
  }

  print(){
    this.results.print();
  }

  save(){
    this.data.saveProject(this.project).then(()=>{
      this.router.navigate(['/dashboard']);
    })
  }

}
