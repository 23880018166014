import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit {

  public action:string;
  public projects$:any;
  public pageTitle:string;

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private data:DataService,
    private dialog:DialogService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.action = params.action;

      switch (this.action){
        case 'copy': this.pageTitle = 'Új Ponton Terv másolatból'; break;
        case 'modify': this.pageTitle = 'Ponton Terv módosítása'; break;
      }

      this.projects$ = this.data.getProjects();
    })
  }

  delete(project:any){
    this.dialog.confirm("Biztosan törölni szeretné a projekt terv verziót?").then(()=>{
      this.data.deleteProject(project);
    })
  }

}
