import { Component, OnInit } from '@angular/core';

import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

  public roles:any[] = [
    { id: undefined, name: 'Tervező' },
    { id: 'admin', name: 'Admin' }
  ];

  public changed:any = {};
  public users$:any;

  constructor(
    private data:DataService,
    private dialog:DialogService
  ) {
    this.users$ = data.getUsers();
  }

  ngOnInit() {
  }

  save(user:any){
    this.data.saveUser(user).then(()=>{
      this.changed[user.key] = false;
    })
  }

  delete(user:any){
    this.dialog.confirm("Biztosan törölni szeretné a felhasználót?").then(()=>{
      user.deleted = true;
      this.data.saveUser(user).then(()=>{})
    })
  }

}
