import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { AboutDialogComponent } from '../dialogs/about-dialog/about-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  public showDialog(component:any, params?:any, options?:any):Promise<any>{
    options = {
      size: 'lg',
      ...options
    };
    const dialog = this.modalService.open(component, options);
    if (params){
      for (let key in params){
        dialog.componentInstance[key] = params[key];
      }
    }
    return dialog.result;
  }

  public confirm(message:string, params?){
    return this.showDialog(ConfirmDialogComponent, {message, params});
  }

  public about(){
    return this.showDialog(AboutDialogComponent, {});
  }
}
