import { Component, OnInit, Input } from '@angular/core';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-design-floating',
  templateUrl: './design-floating.component.html',
  styleUrls: ['./design-floating.component.scss']
})
export class DesignFloatingComponent implements OnInit {

  @Input() project:any;

  public formSettings:any/* = {
    width: { min: 2.5, max: 8 },
    length: { min: 2.5, max: 8 },
    height: { min: 0.8, max: 2 },
    aboveWater: { min: 0.2, max: 1 }
  }*/;

  public wallThicknessTypes:string[] = [
    '10 x 7',
    '10 x 8',
    '10 x 9',
    '10 x 10'
  ];

  public types:string[] = [
    'EPS',
    'XPS'
  ];

  public typeMap:any/* = {
    EPS: {
      density: 15
    },
    XPS: {
      density: 20
    }
  }*/;

  public lastValues:any;

  public get floating(){
    return this.project.floating;
  }

  constructor(data:DataService) {
    let s = data.getDesignSettings().obj.subscribe((designSettings:any) => {
      this.formSettings = designSettings.floatingSettings;
      this.typeMap = designSettings.foamSettings;
      s.unsubscribe();
    });
  }

  ngOnInit() {
  }

  sanitizeForm(){
    for (let field of ['width', 'length', 'height', 'aboveWater']){
      if (typeof(this.floating[field])!=='number') this.floating[field] = this.lastValues ? this.lastValues[field] : 0;

      if (this.floating[field] < this.formSettings[field].min){
        this.floating[field] = this.formSettings[field].min;
      } else if (this.floating[field] > this.formSettings[field].max){
        this.floating[field] = this.formSettings[field].max;
      }
    }
  }

  sizeChanged(floating){
    this.floating.length = floating.length;
    this.floating.width = floating.width;
    this.floating.height = floating.height;
    this.sanitizeForm();
  }

  checkpoint(){
    this.lastValues = {...this.floating};
  }


}
