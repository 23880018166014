import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public dataService:DataService
  ) { }

  ngOnInit() {
  }

  logout() {
    this.afAuth.auth.signOut().then(()=>{
      this.router.navigate(['/auth/login']);
    });
  }

}
