import { Component, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as moment from 'moment';
import * as printJS from 'print-js';
import { DataService } from '../../services/data.service';

declare const jQuery:any;

@Component({
  selector: 'app-result-container',
  templateUrl: './result-container.component.html',
  styleUrls: ['./result-container.component.scss']
})
export class ResultContainerComponent implements AfterViewInit {

  @Input() public project:any;

  public loading:boolean = true;
  private pdfUrl:string;

  constructor(
    private http:HttpClient,
    private data:DataService
  ) {
  }

  ngAfterViewInit() {
    let s = this.data.getDesignSettings().obj.subscribe((designSettings:any) => {
      s.unsubscribe();

      let settings = {
        solarPower: designSettings.solarPower,
        mksz_percent: designSettings.mksz_percent,
        densityMap: {},
        ps_price_factor: {}
      };
      for (let foamType in designSettings.foamSettings){
        settings.densityMap[foamType] = designSettings.foamSettings[foamType].density;
        settings.ps_price_factor[foamType] = designSettings.foamSettings[foamType].price;
      }

      let domain = 'https://pontonterv.complexolution.com';
      this.http.post(domain + '/pdf.php', {
        project: this.project,
        settings: settings
      }, {responseType: 'text'}).subscribe(data => {
        let docx = domain + '/eredmenyek/' + data;
        let pdf = docx.replace(/docx$/, 'pdf');
        console.log("DOCX", docx);
        console.log("PDF", pdf);

        //jQuery('#docview').attr('src', pdf + '?_=' + Date.now());
        this.pdfUrl = pdf + '?_=' + Date.now();
        this.setIframeContent();
        this.loading = false;
      });

    });
  }

  setIframeContent(print?:boolean){
    if (print){
      printJS({
        printable: this.pdfUrl,
        type: 'pdf',
        showModal: true,
        modalMessage: 'PDF betöltése...'
      });
    } else {
      jQuery('#docview').contents().find('body').html( '<style>html,body,iframe{padding:0;margin:0;width:100%;height:100%;}</style><iframe frameborder="0" src="'+this.pdfUrl+'"></iframe>' );
    }
  }

  print(){
    this.setIframeContent(true);
  }
}
