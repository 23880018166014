import {
  Scene,
  Color3,
  Vector3, Axis, Space, Matrix,
  Mesh, MeshBuilder,
  StandardMaterial, PBRMaterial, Texture,
  SceneLoader
} from '@babylonjs/core';

export class FloatingEdge {

  public controlMesh:Mesh;
  public floatingParentMesh:Mesh;

  public meshes:any[] = [];

  public foamMaterial:PBRMaterial;

  public edge:Mesh;

  constructor(scene:Scene, parentMesh:Mesh, side:number){

    /*
    let concreteMaterial = new PBRMaterial("concrete", scene);
    concreteMaterial.albedoTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Base_Color.jpg', scene);
    concreteMaterial.metallicTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Roughness.jpg', scene);
    concreteMaterial.bumpTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Normal.jpg', scene);
    concreteMaterial.ambientTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Ambient_Occlusion.jpg', scene);
    */
    let concreteMaterial = new PBRMaterial("concrete", scene);
    concreteMaterial.albedoColor = new Color3(0.5,0.5,0.5);
    concreteMaterial.metallic = 0;
    concreteMaterial.roughness = 0.9;
    

    this.controlMesh = MeshBuilder.CreateBox("floating", {height: 1, width: 1, depth: 1}, scene);
    this.controlMesh.position = new Vector3(0, 0, 0);

    this.controlMesh.material = new StandardMaterial("transparentMat0", scene);
    this.controlMesh.material.alpha = 0;

    this.floatingParentMesh = MeshBuilder.CreateBox("floatingParent", {height: 2, width: 2, depth: 2}, scene);
    this.floatingParentMesh.material = new StandardMaterial("transparentMatX", scene);
    this.floatingParentMesh.material.alpha = 0;

    SceneLoader.LoadAssetContainer("/assets/models/", "floating-edge.obj", scene, (floatingEdgeContainer)=>{
      try {
        for (let edge1 of floatingEdgeContainer.meshes){
          //edge1.scaling = new Vector3(0.5, 0.5, 0.5);
          edge1.setPivotMatrix(Matrix.Translation(0, 0, 1));
          edge1.position = new Vector3(1 * side, 0, 1);
          edge1.scaling = new Vector3(1, 1, 0.3);
          edge1.rotation = new Vector3(0, Math.PI / 2, 0);
          if (side === -1){
            edge1.rotation = new Vector3(0, - Math.PI / 2, 0);
          }
          edge1.parent = this.floatingParentMesh;
          edge1.material = concreteMaterial;
          edge1.createNormals(true);
          scene.addMesh(edge1);
          this.meshes.push(edge1);
          this.edge = <Mesh>edge1;
          break;
        }
        this.floatingParentMesh.scaling = new Vector3(0.5, 0.5, 0.5);
        this.floatingParentMesh.parent = this.controlMesh;
      } catch(e){
        console.error(e);
      }
    })

    this.controlMesh.parent = parentMesh;
  }
}
