import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';

import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';

import { SceneLoader } from '@babylonjs/core';
import { OBJFileLoader } from '@babylonjs/loaders';

import { DataService } from './services/data.service';
import { DialogService } from './services/dialog.service';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { TechSettingsPageComponent } from './pages/tech-settings-page/tech-settings-page.component';
import { DesignSettingsPageComponent } from './pages/design-settings-page/design-settings-page.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { EditProjectPageComponent } from './pages/edit-project-page/edit-project-page.component';
import { ProjectNameDialogComponent } from './dialogs/project-name-dialog/project-name-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { DesignBuildingComponent } from './components/design-building/design-building.component';
import { DesignFloatingComponent } from './components/design-floating/design-floating.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ResultContainerComponent } from './components/result-container/result-container.component';
import { BuildingViewComponent } from './components/building-view/building-view.component';
import { FloatingViewComponent } from './components/floating-view/floating-view.component';
import { BuildingFloatingViewComponent } from './components/building-floating-view/building-floating-view.component';
import { AboutDialogComponent } from './dialogs/about-dialog/about-dialog.component';

SceneLoader.RegisterPlugin(new OBJFileLoader());

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    SignupPageComponent,
    DashboardPageComponent,
    AppLayoutComponent,
    AuthLayoutComponent,
    UsersPageComponent,
    TechSettingsPageComponent,
    DesignSettingsPageComponent,
    HelpPageComponent,
    ProjectsPageComponent,
    EditProjectPageComponent,
    ProjectNameDialogComponent,
    DesignBuildingComponent,
    DesignFloatingComponent,
    PreviewComponent,
    ResultContainerComponent,
    BuildingViewComponent,
    FloatingViewComponent,
    BuildingFloatingViewComponent,
    ConfirmDialogComponent,
    AboutDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule
  ],
  entryComponents: [
    ProjectNameDialogComponent,
    ConfirmDialogComponent,
    AboutDialogComponent
  ],
  providers: [
    DataService,
    DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
