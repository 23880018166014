import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public params:any = {};
  public message:string;

  constructor(public modal:NgbActiveModal) { }

  ngOnInit() {
    setTimeout(()=>{
      if (this.params){
        for (var key in this.params){
          this[key] = this.params[key];
        }
      }
    }, 100);
  }

  ok(){
    this.modal.close(this);
  }

}
