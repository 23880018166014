import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-name-dialog',
  templateUrl: './project-name-dialog.component.html',
  styleUrls: ['./project-name-dialog.component.scss']
})
export class ProjectNameDialogComponent implements OnInit {

  public newProjectName:string = '';

  constructor(
    public modal:NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
