import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuardModule, AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

import { AppComponent } from './app.component';

import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { TechSettingsPageComponent } from './pages/tech-settings-page/tech-settings-page.component';
import { DesignSettingsPageComponent } from './pages/design-settings-page/design-settings-page.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { EditProjectPageComponent } from './pages/edit-project-page/edit-project-page.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/dashboard']);


const routes: Routes = [
  { path: '', component: AppComponent, children: [
    { path: 'auth', component: AuthLayoutComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }, children: [
      { path: 'login', component: LoginPageComponent },
      { path: 'signup', component: SignupPageComponent },
    ] },

    { path: '', component: AppLayoutComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
      { path: 'dashboard', component: DashboardPageComponent },
      { path: 'project/:projectEditMode/:id', component: EditProjectPageComponent },
      { path: 'users', component: UsersPageComponent },

      { path: 'tech-settings', component: TechSettingsPageComponent },
      { path: 'design-settings', component: DesignSettingsPageComponent },
      { path: 'help', component: HelpPageComponent },
      { path: 'projects/:action', component: ProjectsPageComponent },

      { path: 'project/create', pathMatch: 'full', redirectTo: 'project/create/new' },
      { path: '**', pathMatch: 'full', redirectTo: 'dashboard' }
    ]},

  ]},
];

@NgModule({
  imports: [AngularFireAuthGuardModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
