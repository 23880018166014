import { NgZone, Component, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import {
  Engine, Scene,
  ArcRotateCamera,
  HDRCubeTexture,
  PointerDragBehavior,
  ActionManager, ExecuteCodeAction,
  Vector3,
  Color4
} from '@babylonjs/core';

import { Floating } from '../../lib/floating';
import { FloatingEdge } from '../../lib/floating-edge';

@Component({
  selector: 'floating-view',
  templateUrl: './floating-view.component.html',
  styleUrls: ['./floating-view.component.scss']
})
export class FloatingViewComponent implements AfterViewInit, OnDestroy {

  @Output() public onChange:EventEmitter<any> = new EventEmitter();
  @Input() public floating:any;

  @ViewChild('scene', {static:true}) sceneElement:ElementRef;

  private scene:any;
  private isDragging:boolean = false;

  private useFloatingEdge:boolean = false;

  constructor(
    private zone:NgZone
  ){
  }

  ngAfterViewInit() {
    this.renderScene();
  }

  ngOnDestroy(){
    if (this.scene){
      this.scene.dispose();
      this.scene = null;
    }
  }

  renderScene(){
    if (this.scene){
      this.scene.dispose();
      this.scene = null;
      this.isDragging = false;
    }

    if (!this.floating) {
      return;
    }

    const canvas = this.sceneElement.nativeElement;
    const engine = new Engine(canvas);

    this.scene = new Scene(engine);
    this.scene.clearColor = new Color4(1.0, 1.0, 1.0);

    var hdrTexture = new HDRCubeTexture("/assets/textures/environment2.hdr", this.scene, 128);
    hdrTexture.gammaSpace = false;
    this.scene.environmentTexture = hdrTexture;

    //var camera = new ArcRotateCamera("Camera", 3.74, 1.3, 10, new Vector3(0, 0, -2), this.scene);
    var camera = new ArcRotateCamera("Camera", 3.74, 1.3, 10, new Vector3(0, 0, 0), this.scene);
    this.scene.activeCamera.attachControl(canvas);


    var floatingMiddle = new Floating(this.scene, this.floating, 'length2', true);
    floatingMiddle.controlMesh.position = new Vector3(0, 0, -5);

    var floatingSide = new Floating(this.scene, this.floating, null, true);
    let floatingEdge;
    let floatingEdge2;
    if (this.useFloatingEdge){
      floatingEdge = new FloatingEdge(this.scene, floatingSide.controlMesh, 1);
      floatingEdge2 = new FloatingEdge(this.scene, floatingSide.controlMesh, -1);
    }
    //--------------------------------------------------------------------------
    // resize building

    for (let floating of [floatingSide, floatingMiddle]){

      let controls = [
        { field: 'controlMeshTop', change: 'y', factor: 1 },
        { field: 'controlMeshBottom', change: 'y', factor: -1 },
        { field: 'controlMeshLeft', change: 'x', factor: -1 },
        { field: 'controlMeshRight', change: 'x', factor: 1 },
        //{ field: 'controlMeshFront', change: 'z', factor: -1 },
        //{ field: 'controlMeshBack', change: 'z', factor: 1 },
      ];
      for (let control of controls){
        let dragger = new PointerDragBehavior();
        let draggedScale:any;
        dragger.onDragStartObservable.add((event)=>{
          this.isDragging = true;
          floating[control.field].material.alpha = 0.1;
        });
        dragger.onDragObservable.add((event)=>{
          let delta;
          switch (control.change) {
            case 'x': delta = new Vector3(event.delta.x * control.factor, 0, 0); break;
            case 'y': delta = new Vector3(0, event.delta.y * control.factor, 0); break;
            case 'z': delta = new Vector3(0, 0, event.delta.z * control.factor); break;
          }
          floating.controlMesh.scaling = draggedScale = floating.controlMesh.scaling.add(delta);
          this.zone.run(()=>{
            this.onChange.next({floating: {
              width: Math.round(floating.controlMesh.scaling.z * 2) / 2,
              height: Math.round(floating.controlMesh.scaling.y * 10) / 10,
              length: Math.round(floating.controlMesh.scaling.x * 2) / 2
            }});
          });
        })
        dragger.onDragEndObservable.add((event)=>{
          if (this.isDragging){
            this.zone.run(()=>{
              if (draggedScale){
                floating.controlMesh.scaling = draggedScale;
                /*
                this.floating[floating.lengthParam || 'length'] = Math.min(20, Math.round(floating.controlMesh.scaling.x * 2) / 2);
                this.floating.height = Math.min(1.5, Math.max(0.8, Math.round(floating.controlMesh.scaling.y * 10) / 10));
                this.floating.width = Math.round(floating.controlMesh.scaling.z * 2) / 2;
                */
                this.onChange.next({floating: {
                  width: Math.round(floating.controlMesh.scaling.z * 2) / 2,
                  height: Math.round(floating.controlMesh.scaling.y * 10) / 10,
                  [floating.lengthParam || 'length']: Math.round(floating.controlMesh.scaling.x * 2) / 2
                }});
              }
              this.isDragging = false;
              floating[control.field].material.alpha = 0;
            })
          }
        });
        dragger.moveAttached = false;

        floating[control.field].isPickable = true;
        floating[control.field].actionManager = new ActionManager(this.scene);
        floating[control.field].actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (ev)=>{
          if (!this.isDragging){
            floating[control.field].material.alpha = 0.1;
          }
  	    }));
        floating[control.field].actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (ev)=>{
          if (!this.isDragging){
  	        floating[control.field].material.alpha = 0.0;
          }
  	    }));
        floating[control.field].addBehavior(dragger);
      }

    }
    //--------------------------------------------------------------------------

    engine.runRenderLoop(() => {
      if (!this.scene) return;
      if (this.useFloatingEdge && !floatingEdge.edge){
        return;
      }

      if (camera.radius > 70)    camera.radius = 70;
      if (camera.radius < 10)    camera.radius = 10;


      if (this.floating.useLength2){
        floatingMiddle.controlMesh.setEnabled(true);
        if (this.useFloatingEdge){
          floatingEdge.controlMesh.parent = floatingMiddle.controlMesh;
          floatingEdge2.controlMesh.parent = floatingMiddle.controlMesh;
        }

        if (this.useFloatingEdge){
          floatingEdge.edge.scaling = new Vector3(1, 1, 0.3 * 2.5 / this.floating.length2);
          floatingEdge2.edge.scaling = new Vector3(1, 1, 0.3 * 2.5 / this.floating.length2);
        }

        if (!this.isDragging){
          floatingMiddle.controlMesh.scaling = new Vector3(this.floating.length2, this.floating.height, this.floating.width);
        }
      } else {
        if (this.useFloatingEdge){
          floatingEdge.controlMesh.parent = floatingSide.controlMesh;
          floatingEdge2.controlMesh.parent = floatingSide.controlMesh;
        }
        floatingMiddle.controlMesh.setEnabled(false);
        if (this.useFloatingEdge){
          floatingEdge.edge.scaling = new Vector3(1, 1, 0.3 * 2.5 / this.floating.length);
          floatingEdge2.edge.scaling = new Vector3(1, 1, 0.3 * 2.5 / this.floating.length);
        }
      }

      if (!this.isDragging){
        floatingSide.controlMesh.scaling = new Vector3(this.floating.length, this.floating.height, this.floating.width);
      }

      if (floatingSide.labels['width']){
        floatingSide.labels['width'].position = new Vector3(0, - this.floating.height / 2 - 0.2, - this.floating.width / 2 - 0.2);
        floatingSide.labelTexts['width'].text = ''+this.floating.length+' m';

        floatingSide.labels['height'].position = new Vector3(this.floating.length / 2 + 0.5, 0, - this.floating.width / 2 - 0.6);
        floatingSide.labelTexts['height'].text = ''+this.floating.height+' m';

        floatingSide.labels['length'].position = new Vector3(- this.floating.length / 2 - 0.2, - this.floating.height / 2 - 0.2, 0);
        floatingSide.labelTexts['length'].text = ''+this.floating.width+' m';
      }

      floatingSide.updateFoamMaterial(this.floating.type);
      floatingSide.resize(this.floating);
      floatingMiddle.updateFoamMaterial(this.floating.type);
      floatingMiddle.resize(this.floating);

      this.scene.render();
    });

    window.addEventListener("resize", function () {
      try {
        engine.resize();
      } catch(e) {}
    });
  }

}
