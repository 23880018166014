import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-design-settings-page',
  templateUrl: './design-settings-page.component.html',
  styleUrls: ['./design-settings-page.component.scss']
})
export class DesignSettingsPageComponent implements OnInit {

  public designSettings$:any;

  public wallThicknessTypes:string[] = [
    '10 x 7',
    '10 x 8',
    '10 x 9',
    '10 x 10'
  ];

  public types:string[] = [
    'EPS',
    'XPS'
  ];

  constructor(private data:DataService, private router:Router) {
    this.designSettings$ = data.getDesignSettings();
    this.designSettings$.obj.subscribe(settings => {
      if (!settings.project){

        // Default settings
        settings.project = {
          building: {
            weight: 50,
            length: 20,
            width: 10,
            height: 8,
            roof: 'Lapos tető',
            extraWidth: 0
          },
          floating: {
            length: 2.5,
            useLength2: false,
            width: 2.5,
            height: 0.8,
            aboveWater: 0.2,
            type: 'EPS',
            wallThickness: '10 x 7'
          }
        };

        settings.buildingSettings = {
          width: { min: 0, max: 50 },
          length: { min: 0, max: 50 },
          height: { min: 0, max: 20 },
          weight: { min: 0, max: 9999999 },
          extraWidth: { min: 0, max: 20 }
        };

        settings.floatingSettings = {
          width: { min: 2.5, max: 8 },
          length: { min: 2.5, max: 8 },
          height: { min: 0.8, max: 2 },
          aboveWater: { min: 0.2, max: 1 },
          wallThickness: { min: '10 x 7', max: '10 x 10' }
        };

        settings.foamSettings = {
          EPS: { density: 15, price: 200 },
          XPS: { density: 20, price: 200 }
        };

        settings.solarPower = 260;

        settings.mksz_percent = 10;

        this.save(settings, true);
      }
    });
  }

  ngOnInit() {
  }

  save(settings:any, noRedirect?:boolean){
    this.data.saveDesignSettings(settings);
    if (!noRedirect){
      this.router.navigate(['/dashboard']);
    }
  }

}
