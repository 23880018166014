import {
  Scene,
  Color3,
  Color4,
  Vector3,
  Matrix,
  Plane,
  Mesh, MeshBuilder,
  StandardMaterial, PBRMaterial, Texture,
  SceneLoader
} from '@babylonjs/core';
import { GridMaterial } from '@babylonjs/materials';
import { AdvancedDynamicTexture, Control, DisplayGrid, TextBlock } from '@babylonjs/gui';

export class Building {

  public controlMesh:Mesh;
  public buildingParentMesh:Mesh;

  public controlMeshTop:Mesh;
  public controlMeshBottom:Mesh;
  public controlMeshLeft:Mesh;
  public controlMeshRight:Mesh;
  public controlMeshFront:Mesh;
  public controlMeshBack:Mesh;

  public centerOfMass:Mesh;
  public center:Mesh;

  public labels:any={};
  public labelTexts:any={};

  public rulers:any[];

  public roofMaterial:any;
  public solarMaterial:any;

  public roof:any;

  getRoofMaterial(building?:any){
    return building && building.solar ? this.solarMaterial : this.roofMaterial;
  }

  constructor(scene:Scene, buildingSize?:any, showRuler?:boolean){

    //--------------------------------------------------------------------------
    let woodMaterial = new PBRMaterial("wood", scene);
    woodMaterial.albedoColor = new Color3(0.75,0.75,0.8);
    woodMaterial.bumpTexture = new Texture('/assets/textures/wood1/Wood_plank_007_NORM.jpg', scene);
    woodMaterial.metallic = 0.1;
    woodMaterial.roughness = 0.8;
    //--------------------------------------------------------------------------
    let metalMaterial = new PBRMaterial("metal", scene);
    metalMaterial.albedoTexture = new Texture('/assets/textures/metal3/Metal_Weave_003_basecolor.jpg', scene);
    metalMaterial.metallicTexture = new Texture('/assets/textures/metal3/Metal_Weave_003_metallic.jpg', scene);
    metalMaterial.bumpTexture = new Texture('/assets/textures/metal3/Metal_Weave_003_normal.jpg', scene);
    metalMaterial.ambientTexture = new Texture('/assets/textures/metal3/Metal_Weave_003_ambientOcclusion.jpg', scene);
    metalMaterial.opacityTexture = new Texture('/assets/textures/metal3/Metal_Weave_003_opacity.jpg', scene);
    //--------------------------------------------------------------------------
    let concreteMaterial = new PBRMaterial("concrete", scene);
    concreteMaterial.albedoTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Base_Color.jpg', scene);
    concreteMaterial.metallicTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Roughness.jpg', scene);
    concreteMaterial.bumpTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Normal.jpg', scene);
    concreteMaterial.ambientTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Ambient_Occlusion.jpg', scene);
    //--------------------------------------------------------------------------
    let roofMaterial = new PBRMaterial("roof", scene);
    roofMaterial.albedoColor = new Color3(0.5, 0.0, 0.0);
    roofMaterial.metallic = 0.0;
    roofMaterial.roughness = 0.9;
    this.roofMaterial = roofMaterial;
    //--------------------------------------------------------------------------
    let solarMaterial = new PBRMaterial("solar", scene);
    solarMaterial.albedoColor = new Color3(0, 0, 0);
    solarMaterial.metallic = 0.5;
    solarMaterial.roughness = 0.3;
    this.solarMaterial = solarMaterial;
    //--------------------------------------------------------------------------
    var glass = new PBRMaterial("glass", scene);
    glass.directIntensity = 1.0;
    glass.environmentIntensity = 0.1;
    glass.cameraExposure = 0.66;
    glass.cameraContrast = 1.66;
    glass.microSurface = 1;
    glass.reflectivityColor = new Color3(1.0, 1.0, 1.0);
    glass.albedoColor = new Color3(0.95, 0.95, 0.95);
    glass.alpha = 0.8;
    //--------------------------------------------------------------------------

    this.controlMesh = MeshBuilder.CreateBox("building", {height: 1, width: 1, depth: 1}, scene);
    this.controlMesh.position = new Vector3(0, 0, 0);

    this.controlMeshTop = MeshBuilder.CreateBox("controlMeshTop", {height: 0.1, width: 1, depth: 1}, scene);
    this.controlMeshTop.position = new Vector3(0, 0.5, 0);
    this.controlMeshTop.material = new StandardMaterial("transparentMat1", scene);
    this.controlMeshTop.material.alpha = 0.
    this.controlMeshTop.parent = this.controlMesh;

    this.controlMeshBottom = MeshBuilder.CreateBox("controlMeshBottom", {height: 0.1, width: 1, depth: 1}, scene);
    this.controlMeshBottom.position = new Vector3(0, -0.5, 0);
    this.controlMeshBottom.material = new StandardMaterial("transparentMat2", scene);
    this.controlMeshBottom.material.alpha = 0.
    this.controlMeshBottom.parent = this.controlMesh;

    this.controlMeshLeft = MeshBuilder.CreateBox("controlMeshLeft", {height: 0, width: 0.1, depth: 1}, scene);
    this.controlMeshLeft.position = new Vector3(-0.5, 0, 0);
    this.controlMeshLeft.material = new StandardMaterial("transparentMat3", scene);
    this.controlMeshLeft.material.alpha = 0.
    this.controlMeshLeft.parent = this.controlMesh;

    this.controlMeshRight = MeshBuilder.CreateBox("controlMeshRight", {height: 0, width: 0.1, depth: 1}, scene);
    this.controlMeshRight.position = new Vector3(0.5, 0, 0);
    this.controlMeshRight.material = new StandardMaterial("transparentMat4", scene);
    this.controlMeshRight.material.alpha = 0.
    this.controlMeshRight.parent = this.controlMesh;

    this.controlMeshFront = MeshBuilder.CreateBox("controlMeshFront", {height: 0, width: 0, depth: 0.1}, scene);
    this.controlMeshFront.position = new Vector3(0, 0, -0.5);
    this.controlMeshFront.material = new StandardMaterial("transparentMat5", scene);
    this.controlMeshFront.material.alpha = 0.
    this.controlMeshFront.parent = this.controlMesh;

    this.controlMeshBack = MeshBuilder.CreateBox("controlMeshBack", {height: 0, width: 0, depth: 0.1}, scene);
    this.controlMeshBack.position = new Vector3(0, 0, 0.5);
    this.controlMeshBack.material = new StandardMaterial("transparentMat6", scene);
    this.controlMeshBack.material.alpha = 0.
    this.controlMeshBack.parent = this.controlMesh;

    this.buildingParentMesh = MeshBuilder.CreateBox("buildingParent", {height: 0.4, width: 16, depth: 16}, scene);
    this.buildingParentMesh.setPivotPoint(new Vector3(0, -0.2, 0));
    this.buildingParentMesh.material = concreteMaterial;

    if  (buildingSize){
      this.controlMesh.scaling = new Vector3(buildingSize.length, buildingSize.height, buildingSize.width);
    }
    this.controlMesh.material = new StandardMaterial("transparentMat0", scene);
    this.controlMesh.material.alpha = 0;


    this.centerOfMass = MeshBuilder.CreateSphere("com", {diameter: 1, diameterX: 1}, scene);
    let red = new PBRMaterial("red", scene);
    red.albedoColor = new Color3(1,0,0);
    red.metallic = 0;
    red.roughness = 1;
    this.centerOfMass.material = red;
    let centerOfMassLabel = MeshBuilder.CreateSphere("centerOfMass", { diameter: 1.001, diameterX: 1.001 }, scene);
    //centerOfMassLabel.position = new Vector3(0, 0, 0);
    centerOfMassLabel.rotation = new Vector3(Math.PI, -Math.PI / 4, 0);
    let centerOfMassLabelGui = AdvancedDynamicTexture.CreateForMesh(centerOfMassLabel, 1024, 1024);
    let centerOfMassLabelText = new TextBlock("centerLabel", "Tömeg-\nközéppont");
    centerOfMassLabelText.fontSize = 80;
    centerOfMassLabelText.color = '#fff';
    centerOfMassLabelText.shadowBlur = 5;
    centerOfMassLabelGui.addControl(centerOfMassLabelText);
    centerOfMassLabel.setParent(this.centerOfMass);
    this.centerOfMass.position = new Vector3(0, - buildingSize.height / 6, 0);

    this.center = MeshBuilder.CreateSphere("com", {diameter: 1, diameterX: 1}, scene);
    this.center.position = new Vector3(0, 0, 0);
    let blue = new PBRMaterial("blue", scene);
    blue.albedoColor = new Color3(0,0,1);
    blue.metallic = 0;
    blue.roughness = 1;
    this.center.material = blue;
    let centerLabel = MeshBuilder.CreateSphere("metacenterum", { diameter: 1.001, diameterX: 1.001 }, scene);
    centerLabel.position = new Vector3(0, 0, 0);
    centerLabel.rotation = new Vector3(Math.PI, -Math.PI / 4, 0);
    let centerLabelGui = AdvancedDynamicTexture.CreateForMesh(centerLabel, 1024, 1024);
    let centerLabelText = new TextBlock("centerLabel", "Meta-\ncentrum");
    centerLabelText.fontSize = 80;
    centerLabelText.color = '#fff';
    centerLabelText.shadowBlur = 5;
    centerLabelGui.addControl(centerLabelText);
    centerLabel.setParent(this.center);


    if (showRuler){
      let gm = new GridMaterial("g1", scene);
      gm.opacity = 0.99//0.2;
      gm.gridRatio = 0.1;
      gm.majorUnitFrequency = 5;
      gm.minorUnitVisibility = 0.1;
      gm.backFaceCulling = false;

      for (let f of ['length', 'height', 'width']){
        this.labels[f] = MeshBuilder.CreatePlane("ruler", { width: 5, height: 5 }, scene);
        this.labels[f].position = new Vector3(0, 0, 0);
        var gui = AdvancedDynamicTexture.CreateForMesh(this.labels[f], 1024, 1024);
        this.labelTexts[f] = new TextBlock("b_"+f, "0.0");
        this.labelTexts[f].fontSize = 200;
        gui.addControl(this.labelTexts[f]);
      }
      this.labels.length.rotation = new Vector3(0, Math.PI / 2, 0);
      this.labels.height.rotation = new Vector3(0, Math.PI / 2, 0);

      this.rulers = [];
      for (let i = 0; i < 3; i++){
        let grid = MeshBuilder.CreatePlane("p1", {width:100, height: 0.19}, scene);
        grid.material = gm;
        this.rulers.push(grid);
      }
      this.rulers[1].rotation = new Vector3(0, 0, Math.PI / 2);
      this.rulers[2].rotation = new Vector3(0, Math.PI / 2, 0);
    }

    let modelName:string;
    let heightFactor:number;
    switch (buildingSize.roof){
      case "Lapos tető": modelName = 'building1'; heightFactor = 0.205; break;
      case "Félnyereg tető": modelName = 'building3'; heightFactor = 0.125; break;
      default: modelName = "building2"; heightFactor = 1/8;
    }

    SceneLoader.LoadAssetContainer("/assets/models/", modelName + ".obj", scene, (container)=>{
      for (let mesh of container.meshes){
        mesh.parent = this.buildingParentMesh;
        mesh.createNormals(true);
        if (/roof_/.test(mesh.name)){
          //mesh.material = this.getRoofMaterial(buildingSize);
          mesh.material = roofMaterial;
          this.roof = mesh
        } else if (/roofwall/.test(mesh.name)){
          mesh.material = woodMaterial;
        } else {
          switch (mesh.material.name) {
            case 'wood':
            case 'white_st':
            case 'standart_metal':
              mesh.material = woodMaterial;
              break;
            case 'blue':
              mesh.material = roofMaterial;
              break;
            case 'green':
            case 'black_st':
            case 'yellow':
              mesh.material = concreteMaterial;
              break;
            case 'clear_window':
              mesh.material = glass;
              break;
            default:
              console.log(mesh.material.name);
          }
        }
        scene.addMesh(mesh);
      }

      this.buildingParentMesh.scaling = new Vector3(1/16, heightFactor, 1/16);
      this.buildingParentMesh.rotation = new Vector3(0, Math.PI / 2, 0);
      this.buildingParentMesh.position = new Vector3(0, -0.3, 0);

      this.buildingParentMesh.parent = this.controlMesh;
    })

  }


  resize(floatingSize:any, relativePosition?:Vector3){
    if (!relativePosition){
      relativePosition = new Vector3(0,0,0);
    }

    if (this.rulers){
      this.rulers[0].position = relativePosition.add(new Vector3(0, - floatingSize.height / 2, -floatingSize.width / 2 - 0.1));
      this.rulers[1].position = relativePosition.add(new Vector3(floatingSize.length / 2, 0, -floatingSize.width / 2 - 0.1));
      this.rulers[2].position = relativePosition.add(new Vector3(-floatingSize.length / 2, - floatingSize.height / 2, floatingSize.width / 2 - 0.1));
    }

    this.centerOfMass.position = relativePosition.add(new Vector3(0, - floatingSize.height / 4, 0));
    this.center.position = relativePosition.add(new Vector3(0, 0, 0));

    if (this.roof){
      this.roof.material = this.getRoofMaterial(floatingSize);
    }
  }
}
