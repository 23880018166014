import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public user:any = {};
  public loginError:boolean;

  constructor(
    private afAuth: AngularFireAuth,
    private router:Router,
    private dataService:DataService
  ) { }

  ngOnInit() {
  }

  handleLogin(loginPromise:any, addUserInfo?:boolean){
    loginPromise.then((u:any)=>{
      if (addUserInfo){
        this.dataService.addUserInfo(u.user.uid, {
          name: u.user.displayName,
          email: u.user.email,
          enabled: true
        });
      }
      this.router.navigate(['/dashboard']);
    }).catch(()=>{
      this.loginError = true;
    })
  }

  public login(){
    this.loginError = false;
    this.handleLogin(this.afAuth.auth.signInWithEmailAndPassword(this.user.email, this.user.password))
  }

  public loginWithGoogle(){
    this.loginError = false;
    this.handleLogin(this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()), true);
  }

}
