import {
  Scene,
  Color3, Color4,
  Vector3, Axis, Space, Matrix,
  Mesh, MeshBuilder,
  StandardMaterial, PBRMaterial, Texture,
  SceneLoader, CSG, TransformNode
} from '@babylonjs/core';
import { GridMaterial } from '@babylonjs/materials';
import { AdvancedDynamicTexture, Control, DisplayGrid, TextBlock } from '@babylonjs/gui';

export class Floating {

  public outerWallWidth:number = 0.1;
  public nutSize:number = 0.05;
  public nutHeight:number = 0.1;
  public hookSize:number = 0.05;

  public controlMesh:Mesh;
  public floatingParentMesh:Mesh;

  public controlMeshTop:Mesh;
  public controlMeshBottom:Mesh;
  public controlMeshLeft:Mesh;
  public controlMeshRight:Mesh;
  public controlMeshFront:Mesh;
  public controlMeshBack:Mesh;

  public meshes:any[] = [];

  public foamMaterial:PBRMaterial;

  public labels:any={};
  public labelTexts:any={};

  public walls:any={};

  public separatorWalls:any = [];

  public hooks:any[];
  public hooksBottom:any[];
  public nuts:any[];

  public rulers:any[];
  public foam:any;

  updateFoamMaterial(type?:boolean){
    switch (type || this.floatingSize.type){
      case 'XPS':
        this.foamMaterial.albedoColor = new Color3(0.9, 0.3, 0.2);
        break;
      default:
        this.foamMaterial.albedoColor = new Color3(0.2, 0.5, 1.0);
    }
  }

  constructor(scene:Scene, private floatingSize?:any, public lengthParam?:string, showRuler?:boolean){

    let concreteMaterial = new PBRMaterial("concrete", scene);
    concreteMaterial.albedoColor = new Color3(0.5,0.5,0.5);
    concreteMaterial.metallic = 0;
    concreteMaterial.roughness = 0.9;
    /*
    concreteMaterial.albedoTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Base_Color.jpg', scene);
    concreteMaterial.metallicTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Roughness.jpg', scene);
    concreteMaterial.bumpTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Normal.jpg', scene);
    concreteMaterial.ambientTexture = new Texture('/assets/textures/concrete1/Concrete_Wall_001_Ambient_Occlusion.jpg', scene);
    */

    let foamMaterial = new PBRMaterial("concrete", scene);
    this.foamMaterial = foamMaterial;
    this.updateFoamMaterial();
    foamMaterial.metallic = 0;
    foamMaterial.roughness = 0.9;

    let holeMaterial = new PBRMaterial("hole", scene);
    holeMaterial.metallic = 1;
    holeMaterial.roughness = 0;
    //holeMaterial.emissiveColor = new Color3(1,1,1);

    this.controlMesh = MeshBuilder.CreateBox("floating", {height: 1, width: 1, depth: 1}, scene);
    this.controlMesh.position = new Vector3(0, 0, 0);

    this.controlMeshTop = MeshBuilder.CreateBox("controlMeshTop", {height: 0.1, width: 1, depth: 1}, scene);
    this.controlMeshTop.position = new Vector3(0, 0.6, 0);
    this.controlMeshTop.material = new StandardMaterial("transparentMat1", scene);
    this.controlMeshTop.material.alpha = 0.
    this.controlMeshTop.parent = this.controlMesh;

    this.controlMeshBottom = MeshBuilder.CreateBox("controlMeshBottom", {height: 0.1, width: 1, depth: 1}, scene);
    this.controlMeshBottom.position = new Vector3(0, -0.6, 0);
    this.controlMeshBottom.material = new StandardMaterial("transparentMat2", scene);
    this.controlMeshBottom.material.alpha = 0.
    this.controlMeshBottom.parent = this.controlMesh;

    this.controlMeshLeft = MeshBuilder.CreateBox("controlMeshLeft", {height: 1.5, width: 0.1, depth: 1.5}, scene);
    this.controlMeshLeft.position = new Vector3(-0.6, 0, 0);
    this.controlMeshLeft.material = new StandardMaterial("transparentMat3", scene);
    this.controlMeshLeft.material.alpha = 0.
    this.controlMeshLeft.parent = this.controlMesh;

    this.controlMeshRight = MeshBuilder.CreateBox("controlMeshRight", {height: 1.5, width: 0.1, depth: 1.5}, scene);
    this.controlMeshRight.position = new Vector3(0.6, 0, 0);
    this.controlMeshRight.material = new StandardMaterial("transparentMat4", scene);
    this.controlMeshRight.material.alpha = 0.
    this.controlMeshRight.parent = this.controlMesh;

    this.controlMeshFront = MeshBuilder.CreateBox("controlMeshFront", {height: 0, width: 0, depth: 0.1}, scene);
    this.controlMeshFront.position = new Vector3(0, 0, -0.6);
    this.controlMeshFront.material = new StandardMaterial("transparentMat5", scene);
    this.controlMeshFront.material.alpha = 0.
    this.controlMeshFront.parent = this.controlMesh;

    this.controlMeshBack = MeshBuilder.CreateBox("controlMeshBack", {height: 0, width: 0, depth: 0.1}, scene);
    this.controlMeshBack.position = new Vector3(0, 0, 0.6);
    this.controlMeshBack.material = new StandardMaterial("transparentMat6", scene);
    this.controlMeshBack.material.alpha = 0.
    this.controlMeshBack.parent = this.controlMesh;

    if  (floatingSize){
      this.controlMesh.scaling = new Vector3(lengthParam ? floatingSize[lengthParam] : floatingSize.length, floatingSize.height, floatingSize.width);
    }
    this.controlMesh.material = new StandardMaterial("transparentMat0", scene);
    this.controlMesh.material.alpha = 0;

    this.floatingParentMesh = MeshBuilder.CreateBox("floatingParent", {height: 2, width: 2, depth: 2}, scene);
    this.floatingParentMesh.material = new StandardMaterial("transparentMatX", scene);
    this.floatingParentMesh.material.alpha = 0;


    if (showRuler){
      let gm = new GridMaterial("g1", scene);
      gm.opacity = 0.99//0.2;
      gm.gridRatio = 0.1;
      gm.majorUnitFrequency = 5;
      gm.minorUnitVisibility = 0.1;
      gm.backFaceCulling = false;

      this.rulers = [];
      for (let i = 0; i < 3; i++){
        let grid = MeshBuilder.CreatePlane("p1", {width:100, height: 0.1}, scene);
        grid.material = gm;
        this.rulers.push(grid);
      }
      this.rulers[1].rotation = new Vector3(0, 0, Math.PI / 2);
      this.rulers[2].rotation = new Vector3(0, Math.PI / 2, 0);

      for (let f of ['length', 'height', 'width']){
        this.labels[f] = MeshBuilder.CreatePlane("ruler", { width: 1, height: 1 }, scene); // MeshBuilder.CreateBox("uiParent", {height: 1, width: 1, depth: 1}, scene);
        this.labels[f].position = new Vector3(0, 0, 0);
        var gui = AdvancedDynamicTexture.CreateForMesh(this.labels[f], 1024, 1024);
        this.labelTexts[f] = new TextBlock("b_"+f, "0.0");
        this.labelTexts[f].fontSize = 200;
        gui.addControl(this.labelTexts[f]);
      }

      this.labels.length.rotation = new Vector3(0, Math.PI / 2, 0);
      this.labels.height.rotation = new Vector3(0, Math.PI / 2, 0);

      this.nuts = [];
      for (let i = 0; i < 8; i++){
        let nut = MeshBuilder.CreateBox('nut'+i, {width: 1, height: this.nutHeight, depth: 1}, scene);
        nut.material = i < 4 ? concreteMaterial : holeMaterial;
        this.nuts.push(nut);
      }

      SceneLoader.LoadAssetContainer("/assets/models/", "hook.obj", scene, (container)=>{
        this.hooks = [];
        this.hooksBottom = [];
        let hookParent = MeshBuilder.CreateBox("b1", {width: 1, height: 1, depth: 1}, scene)
        hookParent.material = new StandardMaterial("transparentMat5", scene);
        hookParent.material.alpha = 0;
        this.hookSize = 0.05;
        hookParent.scaling = new Vector3(this.hookSize,this.hookSize,this.hookSize);

        for (let mesh of container.meshes){
          if (mesh.name !== 'Telfer'){
            mesh.material = concreteMaterial;
            mesh.parent = hookParent;
            scene.addMesh(mesh);
          }
          //this.meshes.push(mesh);
        }
        hookParent.position = new Vector3(0, 0, 0);
        this.hooks.push( hookParent );
        for (let hi = 1; hi <= 7; hi++){
          this.hooks.push(hookParent.clone("hook"+hi))
        }
        for (let hi = 0; hi <= 7; hi++){
          this.hooksBottom.push(hookParent.clone("hookBottom"+hi))
          this.hooksBottom[hi].rotation = new Vector3(Math.PI, 0, 0);
        }
      })
    }

    let foam = this.foam = MeshBuilder.CreateBox('foam', {width: 1, height: 1, depth: 1}, scene);
    foam.material = foamMaterial;
    //foam.parent = this.floatingParentMesh;

    for (let w of ['top', 'front', 'back', 'left', 'right']){
      this.walls[w] = MeshBuilder.CreateBox("wall_"+w, { width: 1, height: 1, depth: this.outerWallWidth }, scene);
      this.walls[w].position = new Vector3(0, 0, 0);
      this.walls[w].material = concreteMaterial;
    }
    this.walls.top.rotation = new Vector3(Math.PI / 2, 0, 0);
    this.walls.front.rotation = new Vector3(0, Math.PI / 2, 0);
    this.walls.back.rotation = new Vector3(0, -Math.PI / 2, 0);

    let sw = 0;
    for (sw = 0; sw < 2; sw++){
      let w2 = MeshBuilder.CreateBox("swall_"+sw, { width: 1, height: 1, depth: this.outerWallWidth }, scene);
      w2.rotation = new Vector3(0, Math.PI / 2, 0);
      w2.material = concreteMaterial;
      this.separatorWalls.push(w2);
    }

    /*SceneLoader.LoadAssetContainer("/assets/models/", "floating.obj", scene, (container)=>{
      for (let mesh of container.meshes){
        mesh.parent = this.floatingParentMesh;
        mesh.createNormals(true);
        switch (mesh.name) {
          case 'ConcreteBox_Cube':
            mesh.material = concreteMaterial;
            break;
          case 'FillMaterial_Cube.001':
            mesh.material = foamMaterial;
            break;
          default:
            console.log(mesh.name);
        }
        scene.addMesh(mesh);
        this.meshes.push(mesh);
      }*/

      this.floatingParentMesh.scaling = new Vector3(0.5, 0.5, 0.5);
      this.floatingParentMesh.parent = this.controlMesh;
    //})


    this.resize(floatingSize);

    if (this.nuts){
      let root = new TransformNode("");
      this.walls.right.parent = root;
      this.nuts[4].parent = root;
      this.nuts[5].parent = root;
      let hole = CSG.FromMesh(this.nuts[4]);
      let hole2 = CSG.FromMesh(this.nuts[5]);
      let holex = CSG.FromMesh(this.nuts[6]);
      let holex2 = CSG.FromMesh(this.nuts[7]);

      let wall = CSG.FromMesh(this.walls.right);

      let newHolePlate = wall.subtract(hole).subtract(hole2).subtract(holex).subtract(holex2);
      let newMeshHolePlate = newHolePlate.toMesh("rwn1", concreteMaterial, scene, true);
      this.walls.right.setEnabled(false);
      this.walls.right = newMeshHolePlate;

      let root2 = new TransformNode("");
      this.walls.back.parent = root2;
      this.nuts[6].parent = root2;
      this.nuts[7].parent = root2;

      let wallx = CSG.FromMesh(this.walls.back);

      let newHolePlatex = wallx.subtract(hole).subtract(hole2).subtract(holex).subtract(holex2);
      let newMeshHolePlatex = newHolePlatex.toMesh("rwn1", concreteMaterial, scene, true);
      this.walls.back.setEnabled(false);
      this.walls.back = newMeshHolePlatex;

      this.nuts[4].setEnabled(false)
      this.nuts[5].setEnabled(false)
      this.nuts[6].setEnabled(false)
      this.nuts[7].setEnabled(false)

      this.resize(floatingSize);
    }
  }

  resize(floatingSize:any, relativePosition?:Vector3){
    if (!relativePosition){
      relativePosition = new Vector3(0,0,0);
    }
    this.walls.top.position = relativePosition.add(new Vector3(0, floatingSize.height / 2 - this.outerWallWidth / 2, 0));
    this.walls.top.scaling =  new Vector3(floatingSize.length - this.outerWallWidth * 2, floatingSize.width - this.outerWallWidth * 2, 1);

    this.walls.front.position = relativePosition.add(new Vector3(-floatingSize.length / 2 + this.outerWallWidth / 2, 0, -this.outerWallWidth / 2));
    this.walls.front.scaling = new Vector3(floatingSize.width-this.outerWallWidth, floatingSize.height, 1);

    this.walls.back.position = relativePosition.add(new Vector3(floatingSize.length / 2 - this.outerWallWidth / 2, 0, 0));
    this.walls.back.scaling = new Vector3(floatingSize.width, floatingSize.height, 1);

    this.walls.left.position = relativePosition.add(new Vector3(0, 0, -floatingSize.width / 2 + this.outerWallWidth / 2));
    this.walls.left.scaling = new Vector3(floatingSize.length - this.outerWallWidth * 2, floatingSize.height, 1);

    this.walls.right.position = relativePosition.add(new Vector3(-this.outerWallWidth / 2, 0, floatingSize.width / 2 - this.outerWallWidth / 2));
    this.walls.right.scaling = new Vector3(floatingSize.length - this.outerWallWidth, floatingSize.height, 1);

    this.foam.scaling = new Vector3(floatingSize.length - this.outerWallWidth * 2, floatingSize.height - this.outerWallWidth * 2, floatingSize.width - this.outerWallWidth * 2);
    this.foam.position = relativePosition;

    if (this.hooks){
      let n = 0;
      for (let pos of [ [1,1], [-1,1], [-1,-1], [1,-1] ]){
        this.hooks[n].position = relativePosition.add(new Vector3(floatingSize.length / 2 * pos[1] * 0.95, floatingSize.height / 2, floatingSize.width / 2 * pos[0] * 0.95));
        n++;
      }
      for (n = 4; n < 8; n++) {
        this.hooks[n].setEnabled(false);
      }
      if (floatingSize.length >= 5.5 /*&& floatingSize.length < 7*/){
        /*this.hooks[4].position = relativePosition.add(new Vector3(0, floatingSize.height / 2, floatingSize.width / 2 * 0.95));
        this.hooks[4].setEnabled(true);

        this.hooks[5].position = relativePosition.add(new Vector3(0, floatingSize.height / 2, - floatingSize.width / 2 * 0.95));
        this.hooks[5].setEnabled(true);
      } else if (floatingSize.length >= 7){*/
        this.hooks[4].position = relativePosition.add(new Vector3(floatingSize.length / 6, floatingSize.height / 2, floatingSize.width / 2 * 0.95));
        this.hooks[4].setEnabled(true);

        this.hooks[5].position = relativePosition.add(new Vector3(floatingSize.length / 6/*floatingSize.length / 2 * 0.5*/, floatingSize.height / 2, - floatingSize.width / 2 * 0.95));
        this.hooks[5].setEnabled(true);

        this.hooks[6].position = relativePosition.add(new Vector3(-floatingSize.length / 6/*floatingSize.length / 2 * 0.5*/, floatingSize.height / 2, floatingSize.width / 2 * 0.95));
        this.hooks[6].setEnabled(true);

        this.hooks[7].position = relativePosition.add(new Vector3(-floatingSize.length / 6/*floatingSize.length / 2 * 0.5*/, floatingSize.height / 2, - floatingSize.width / 2 * 0.95));
        this.hooks[7].setEnabled(true);
      }
    }

    if (this.hooksBottom){
      let n = 0;
      for (let pos of [ [1,1], [-1,1], [-1,-1], [1,-1] ]){
        this.hooksBottom[n].position = relativePosition.add(new Vector3(floatingSize.length / 2 * pos[1] * 0.95, -floatingSize.height / 2, floatingSize.width / 2 * pos[0] * 0.95));
        n++;
      }
      for (n = 4; n < 8; n++) {
        this.hooksBottom[n].setEnabled(false);
      }
      if (floatingSize.length >= 5.5 /*&& floatingSize.length < 7*/){
        /*this.hooks[4].position = relativePosition.add(new Vector3(0, floatingSize.height / 2, floatingSize.width / 2 * 0.95));
        this.hooks[4].setEnabled(true);

        this.hooks[5].position = relativePosition.add(new Vector3(0, floatingSize.height / 2, - floatingSize.width / 2 * 0.95));
        this.hooks[5].setEnabled(true);
      } else if (floatingSize.length >= 7){*/
        this.hooksBottom[4].position = relativePosition.add(new Vector3(floatingSize.length / 6, -floatingSize.height / 2, floatingSize.width / 2 * 0.95));
        this.hooksBottom[4].setEnabled(true);

        this.hooksBottom[5].position = relativePosition.add(new Vector3(floatingSize.length / 6/*floatingSize.length / 2 * 0.5*/, -floatingSize.height / 2, - floatingSize.width / 2 * 0.95));
        this.hooksBottom[5].setEnabled(true);

        this.hooksBottom[6].position = relativePosition.add(new Vector3(-floatingSize.length / 6/*floatingSize.length / 2 * 0.5*/, -floatingSize.height / 2, floatingSize.width / 2 * 0.95));
        this.hooksBottom[6].setEnabled(true);

        this.hooksBottom[7].position = relativePosition.add(new Vector3(-floatingSize.length / 6/*floatingSize.length / 2 * 0.5*/, -floatingSize.height / 2, - floatingSize.width / 2 * 0.95));
        this.hooksBottom[7].setEnabled(true);
      }
    }


    if (this.separatorWalls){
      for (let i = 0; i < this.separatorWalls.length; i++){
        this.separatorWalls[i].setEnabled(false);
        this.separatorWalls[i].scaling = new Vector3(floatingSize.width - 2 * this.outerWallWidth, floatingSize.height, 1);
      }
      if (floatingSize.length >= 5.5 && floatingSize.length < 7){
        this.separatorWalls[0].setEnabled(true);
        this.separatorWalls[0].position = relativePosition.add(new Vector3(0, 0, 0));
      } else if (floatingSize.length >= 7) {
        this.separatorWalls[0].setEnabled(true);
        this.separatorWalls[0].position = relativePosition.add(new Vector3(-floatingSize.length / 6, 0, 0));
        this.separatorWalls[1].setEnabled(true);
        this.separatorWalls[1].position = relativePosition.add(new Vector3(floatingSize.length / 6, 0, 0));
      }
    }

    if (this.rulers){
      this.rulers[0].position = relativePosition.add(new Vector3(0, - floatingSize.height / 2, -floatingSize.width / 2 - 0.1));
      this.rulers[1].position = relativePosition.add(new Vector3(floatingSize.length / 2, 0, -floatingSize.width / 2 - 0.1));
      this.rulers[2].position = relativePosition.add(new Vector3(-floatingSize.length / 2, - floatingSize.height / 2, floatingSize.width / 2 - 0.1));
    }

    if (this.nuts){
      this.nuts[0].scaling = new Vector3(floatingSize.length,floatingSize.height,this.nutSize);
      this.nuts[0].position = relativePosition.add(new Vector3(0, floatingSize.height / 6, - floatingSize.width / 2 - this.nutSize / 2));
      this.nuts[1].scaling = new Vector3(floatingSize.length,floatingSize.height,this.nutSize);
      this.nuts[1].position = relativePosition.add(new Vector3(0, - floatingSize.height / 6, - floatingSize.width / 2 - this.nutSize / 2));

      this.nuts[2].scaling = new Vector3(this.nutSize,floatingSize.height,floatingSize.width);
      this.nuts[2].position = relativePosition.add(new Vector3(- floatingSize.length / 2 - this.nutSize / 2, floatingSize.height / 6,  - this.outerWallWidth/2));
      this.nuts[3].scaling = new Vector3(this.nutSize,floatingSize.height,floatingSize.width);
      this.nuts[3].position = relativePosition.add(new Vector3(- floatingSize.length / 2 - this.nutSize / 2, - floatingSize.height / 6, - this.outerWallWidth/2));

      this.nuts[4].scaling = new Vector3(floatingSize.length,1,this.nutSize);
      this.nuts[4].position = relativePosition.add(new Vector3(0, floatingSize.height / 6, floatingSize.width / 2 - this.nutSize / 2 + 0.0001));
      this.nuts[5].scaling = new Vector3(floatingSize.length,1,this.nutSize);
      this.nuts[5].position = relativePosition.add(new Vector3(0, - floatingSize.height / 6, floatingSize.width / 2 - this.nutSize / 2 + 0.0001));

      this.nuts[6].scaling = new Vector3(this.nutSize,1,floatingSize.width);
      this.nuts[6].position = relativePosition.add(new Vector3(floatingSize.length / 2 - this.nutSize / 2 + 0.0001, floatingSize.height / 6, 0));
      this.nuts[7].scaling = new Vector3(this.nutSize,1,floatingSize.width);
      this.nuts[7].position = relativePosition.add(new Vector3(floatingSize.length / 2 - this.nutSize / 2 + 0.0001, - floatingSize.height / 6, 0));

      //this.nuts[1].position = relativePosition.add(new Vector3(- floatingSize.length / 2 - this.nutSize / 2 + 0.001, - floatingSize.height / 6, 0));

      /*let n = 0;
      for (let dir = 0; dir <= 1; dir++){
        let x = 0;
        for (let pos of [ [1,1], [-1,1], [-1,-1], [1,-1] ]){
          if (x <= 1){
            if (dir){
              this.nuts[n].position = relativePosition.add(new Vector3(floatingSize.length / 2 * pos[1] - this.nutSize / 2 + 0.001, floatingSize.height / 2 - this.nutSize / 2 + 0.001, floatingSize.width / 2 * pos[0] * 0.6));
              //this.nuts[n].position = relativePosition.add( new Vector3(floatingSize.width * pos[1] - 0.1 + 0.0001, floatingSize.height - 0.3 + 0.0001, floatingSize.length * pos[0] * 0.6));
            } else {
              this.nuts[n].position = relativePosition.add(new Vector3(floatingSize.length / 2 * pos[0] * 0.6, floatingSize.height / 2 - this.nutSize / 2 + 0.001, floatingSize.width / 2 * pos[1] - this.nutSize / 2 + 0.001 ));
              //this.nuts[n].position = new Vector3(pos[0] * 0.6, 1 - 0.3 + 0.0001, pos[1] - 0.1 + 0.0001);
            }
          } else {
            if (dir){
              this.nuts[n].position = relativePosition.add(new Vector3(floatingSize.length / 2 * pos[1] - this.nutSize / 2 + 0.001, floatingSize.height / 2 - this.nutSize / 2 + 0.001, floatingSize.width / 2 * pos[0] * 0.6));
              //this.nuts[n].position = new Vector3(pos[1] - 0.1, 1 - 0.3, pos[0] * 0.6);
            } else {
              this.nuts[n].position = relativePosition.add(new Vector3(floatingSize.length / 2 * pos[0] * 0.6, floatingSize.height / 2 - this.nutSize / 2 + 0.001, floatingSize.width / 2 * pos[1] - this.nutSize / 2 + 0.001 ));
              //this.nuts[n].position = new Vector3(pos[0] * 0.6, 1 - 0.3, pos[1] - 0.1);
            }
          }
          //nut.setParent(this.floatingParentMesh);
          n++;
          x++;
        }
      }*/
    }
  }

}
