import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  @Input() project:any;

  public get floating(){
    return this.project.floating;
  }

  public get building(){
    return this.project.building;
  }


  constructor() { }

  ngOnInit() {
  }

}
