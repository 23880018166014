import { Component, OnInit } from '@angular/core';

import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  public projects$:any;

  constructor(
    public data:DataService,
    private dialog:DialogService
  ) {
    this.projects$ = this.data.getProjects();
  }

  ngOnInit() {
  }

  about(){
    this.dialog.about();
  }

}
