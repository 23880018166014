import { Component, OnInit, Input } from '@angular/core';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-design-building',
  templateUrl: './design-building.component.html',
  styleUrls: ['./design-building.component.scss']
})
export class DesignBuildingComponent implements OnInit {

  @Input() project:any;

  public formSettings:any;

  public lastValues:any;

  public roofTypes:any[] = [
    "Lapos tető",
    "Nyereg tető",
    "Félnyereg tető"
  ];

  public show3d:boolean = true;

  public get building(){
    return this.project.building;
  }

  constructor(data:DataService) {
    let s = data.getDesignSettings().obj.subscribe((designSettings:any) => {
      this.formSettings = designSettings.buildingSettings;
      s.unsubscribe();
    });
  }

  ngOnInit() {
    if (this.project && !this.project.building.roof){
      this.project.building.roof = this.roofTypes[1];
    }
  }

  sanitizeForm(){
    for (let field of ['width', 'length', 'height', 'extraWidth', 'weight']){
      if (typeof(this.building[field])!=='number') this.building[field] = this.lastValues ? this.lastValues[field] : 0;
      if (this.building[field] < this.formSettings[field].min){
        this.building[field] = this.formSettings[field].min;
      } else if (this.building[field] > this.formSettings[field].max){
        this.building[field] = this.formSettings[field].max;
      }
    }
  }

  sizeChanged(building){
    this.building.length = building.length;
    this.building.width = building.width;
    this.building.height = building.height;
    this.sanitizeForm();
  }

  checkpoint(){
    this.lastValues = {...this.building};
  }

  updateModelType(){
    this.show3d = false;
    setTimeout(()=>{
      this.show3d = true;
    })
  }

}
