import { NgZone, Component, Input, Output, AfterViewInit, ViewChild, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import {
  Engine, Scene,
  ArcRotateCamera,
  HDRCubeTexture,
  PointerDragBehavior,
  ActionManager, ExecuteCodeAction,
  Vector3,
  Color4
} from '@babylonjs/core';

import { Building } from '../../lib/building';

@Component({
  selector: 'building-view',
  templateUrl: './building-view.component.html',
  styleUrls: ['./building-view.component.scss']
})
export class BuildingViewComponent implements AfterViewInit, OnDestroy {

  @Output() public onChange:EventEmitter<any> = new EventEmitter();
  @Input() public building:any;

  @ViewChild('scene', {static:true}) sceneElement:ElementRef;

  private scene:any;
  private isDragging:boolean = false;

  constructor(
    private zone:NgZone
  ){
  }

  ngAfterViewInit() {
    this.renderScene();
  }

  ngOnDestroy(){
    if (this.scene){
      this.scene.dispose();
      this.scene = null;
    }
  }

  renderScene(){
    if (this.scene){
      this.scene.dispose();
      this.scene = null;
      this.isDragging = false;
    }

    if (!this.building) {
      return;
    }

    const canvas = this.sceneElement.nativeElement;
    const engine = new Engine(canvas);

    this.scene = new Scene(engine);
    this.scene.clearColor = new Color4(1.0, 1.0, 1.0);

    var hdrTexture = new HDRCubeTexture("/assets/textures/environment2.hdr", this.scene, 128);
    hdrTexture.gammaSpace = false;
    this.scene.environmentTexture = hdrTexture;

    var camera = new ArcRotateCamera("Camera", 3.74, 1.3, 30, new Vector3(0, 0, 0), this.scene);
    this.scene.activeCamera.attachControl(canvas);

    var building = new Building(this.scene, this.building, true);

    //--------------------------------------------------------------------------
    // resize building

    let controls = [
      { field: 'controlMeshTop', change: 'y', factor: 1 },
      { field: 'controlMeshBottom', change: 'y', factor: -1 },
      { field: 'controlMeshLeft', change: 'x', factor: -1 },
      { field: 'controlMeshRight', change: 'x', factor: 1 },
      { field: 'controlMeshFront', change: 'z', factor: -1 },
      { field: 'controlMeshBack', change: 'z', factor: 1 },
    ];
    for (let control of controls){
      let dragger = new PointerDragBehavior();
      let draggedScale:any;
      dragger.onDragStartObservable.add((event)=>{
        this.isDragging = true;
        building[control.field].material.alpha = 0.1;
      });
      dragger.onDragObservable.add((event)=>{
        let delta;
        switch (control.change) {
          case 'x': delta = new Vector3(event.delta.x * control.factor, 0, 0); break;
          case 'y': delta = new Vector3(0, event.delta.y * control.factor, 0); break;
          case 'z': delta = new Vector3(0, 0, event.delta.z * control.factor); break;
        }
        building.controlMesh.scaling = draggedScale = building.controlMesh.scaling.add(delta);
        this.zone.run(()=>{
          this.onChange.next({building: {
            width: Math.round(building.controlMesh.scaling.z * 2) / 2,
            height: Math.round(building.controlMesh.scaling.y * 2) / 2,
            length: Math.round(building.controlMesh.scaling.x * 2) / 2
          }});
        });
      })
      dragger.onDragEndObservable.add((event)=>{
        if (this.isDragging){
          this.zone.run(()=>{
            if (draggedScale){
              building.controlMesh.scaling = draggedScale;
              /*
              this.building.length = Math.round(building.controlMesh.scaling.x);
              this.building.height = Math.round(building.controlMesh.scaling.y);
              this.building.width = Math.round(building.controlMesh.scaling.z);
              */
              this.onChange.next({building: {
                width: Math.round(building.controlMesh.scaling.z * 2) / 2,
                height: Math.round(building.controlMesh.scaling.y * 2) / 2,
                length: Math.round(building.controlMesh.scaling.x * 2) / 2
              }});
            }
            this.isDragging = false;
            building[control.field].material.alpha = 0;
          })
        }
      });
      dragger.moveAttached = false;

      building[control.field].isPickable = true;
      building[control.field].actionManager = new ActionManager(this.scene);
      building[control.field].actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (ev)=>{
        if (!this.isDragging){
          building[control.field].material.alpha = 0.1;
        }
	    }));
      building[control.field].actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (ev)=>{
        if (!this.isDragging){
	        building[control.field].material.alpha = 0.0;
        }
	    }));
      building[control.field].addBehavior(dragger);
    }

    //--------------------------------------------------------------------------

    engine.runRenderLoop(() => {
      if (!this.scene) return;
      if (!this.isDragging){
        building.controlMesh.scaling = new Vector3(this.building.length, this.building.height, this.building.width);
      }
      if (building.labels['width']){
        building.labels['width'].position = new Vector3(0, - this.building.height / 2 - 1.2, - this.building.width / 2 - 0.2);
        building.labelTexts['width'].text = ''+this.building.length+' m';

        building.labels['height'].position = new Vector3(this.building.length / 2 + 0.5, -0.2, - this.building.width / 2 - 1);
        building.labelTexts['height'].text = ''+this.building.height+' m';

        building.labels['length'].position = new Vector3(- this.building.length / 2 - 1.2, - this.building.height / 2 - 0.6, 0);
        building.labelTexts['length'].text = ''+this.building.width+' m';
      }

      building.resize(this.building);
      this.scene.render();
    });

    window.addEventListener("resize", function () {
      try {
        engine.resize();
      } catch(e) {}
    });
  }

}
