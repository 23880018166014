import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {

  public user:any = {};
  public signupError:boolean;

  constructor(
    private afAuth: AngularFireAuth,
    private router:Router,
    private dataService:DataService
  ) { }

  ngOnInit() {
  }

  handleSignup(signupPromise:any){
    signupPromise.then(()=>{
      this.afAuth.auth.signInWithEmailAndPassword(this.user.email, this.user.password).then((u)=>{
        this.dataService.addUserInfo(u.user.uid, {
          name: this.user.name,
          email: this.user.email,
          enabled: true
        });
        this.router.navigate(['/dashboard']);
      })
    }).catch(()=>{
      this.signupError = true;
    })
  }

  public signup(){
    this.signupError = false;
    this.handleSignup(this.afAuth.auth.createUserWithEmailAndPassword(this.user.email, this.user.password))
  }


}
