export const environment = {
  production: false,
  version: '1.3.1',
  firebase: {
    apiKey: "AIzaSyAudbj1bhh_YkUy0fvVVoB577zRTmGZPNw",
    authDomain: "pontonterv.firebaseapp.com",
    databaseURL: "https://pontonterv.firebaseio.com",
    projectId: "pontonterv",
    storageBucket: "pontonterv.appspot.com",
    messagingSenderId: "568375194575",
    appId: "1:568375194575:web:87c66ef0907c3430f7d8ba"
  }
};
