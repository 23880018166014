import { NgZone, Component, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  Engine, Scene,
  ArcRotateCamera,
  HDRCubeTexture,
  PointLight,
  Texture, StandardMaterial,
  Vector2, Vector3, PBRMaterial,
  Mesh, MeshBuilder,
  Color3, Color4
} from '@babylonjs/core';
import {
  WaterMaterial
} from '@babylonjs/materials';

import { Floating } from '../../lib/floating';
//import { FloatingEdge } from '../../lib/floating-edge';
import { Building } from '../../lib/building';


@Component({
  selector: 'building-floating-view',
  templateUrl: './building-floating-view.component.html',
  styleUrls: ['./building-floating-view.component.scss']
})
export class BuildingFloatingViewComponent implements AfterViewInit, OnDestroy {

  @Input() public floating:any;
  @Input() public building:any;

  @ViewChild('scene', {static:true}) sceneElement:ElementRef;
  private scene:any;

  constructor(
    private zone:NgZone
  ){
  }

  ngAfterViewInit() {
    this.renderScene();
  }

  ngOnDestroy(){
    if (this.scene){
      this.scene.dispose();
      this.scene = null;
    }
  }

  renderScene(){
    if (this.scene){
      this.scene.dispose();
      this.scene = null;
    }

    if (!this.floating) {
      return;
    }

    const canvas = this.sceneElement.nativeElement;
    const engine = new Engine(canvas);

    this.scene = new Scene(engine);
    this.scene.clearColor = new Color4(0.5, 0.6, 0.8);

    var hdrTexture = new HDRCubeTexture("/assets/textures/environment2.hdr", this.scene, 128);
    hdrTexture.gammaSpace = false;
    this.scene.environmentTexture = hdrTexture;

    var camera = new ArcRotateCamera("Camera", 3.74, 2.0, 40, new Vector3(0, 0, 0), this.scene);
    this.scene.activeCamera.attachControl(canvas);

    var building = new Building(this.scene, this.building);
    building.controlMesh.position = new Vector3(0, this.building.height / 2 + this.floating.aboveWater, 0);

    //var rows, cols, rotation, w, l, ow, ol;

    let buildingWidth = this.building.width + this.building.extraWidth;
    let buildingLength = this.building.length + this.building.extraWidth;

    let qw1, ql1, qw2, ql2, qp1 = 0, qp2 = 0;

    if (this.floating.useLength2) {
      qw1 = Math.ceil(buildingWidth / this.floating.width);
      ql1 = Math.ceil((buildingLength - 2 * this.floating.length2) / this.floating.length);
      qp1 = qw1 * 2;
      qw2 = Math.ceil((buildingWidth - 2 * this.floating.length2) / this.floating.length);
      ql2 = Math.ceil(buildingLength / this.floating.width);
      qp2 = ql2 * 2;
    } else {
      qw1 = Math.ceil(buildingWidth / this.floating.width);
      ql1 = Math.ceil(buildingLength / this.floating.length);
      qw2 = Math.ceil(buildingWidth / this.floating.length);
      ql2 = Math.ceil(buildingLength / this.floating.width);
    }

    let totalWidth, totalLength; //, floatingEdge1, floatingEdge2;

    let floatings:any[] = [];

    if (qw1 * ql1 + qp1 > qw2 * ql2 + qp2) {
      totalWidth = qw2 * this.floating.length + 2 * (this.floating.useLength2 ? this.floating.length2 : 0);
      totalLength = ql2 * this.floating.width;

      let wrapperBox1 = MeshBuilder.CreateBox("wrapperBox", {height: 1, width: 1, depth: 1}, this.scene)
      wrapperBox1.material = new StandardMaterial("transparent", this.scene);
      wrapperBox1.material.alpha = 0;
      wrapperBox1.position = new Vector3(0, - this.floating.height / 2 + this.floating.aboveWater, 0);
      wrapperBox1.scaling = new Vector3(totalWidth, this.floating.height, totalLength);
      wrapperBox1.rotation = new Vector3(0, Math.PI / 2, 0);
      //floatingEdge1 = new FloatingEdge(this.scene, wrapperBox1, -1);
      //floatingEdge2 = new FloatingEdge(this.scene, wrapperBox1, 1);

      let rowMax = qw2 + (this.floating.useLength2 ? 2 : 0);
      let x = -totalWidth / 2;
      for (let row = 0; row < rowMax; row++) {
        let y = -totalLength / 2;
        let l = (row === 0 || row === rowMax-1) && this.floating.useLength2 ? this.floating.length2 : this.floating.length;
        let w = this.floating.width;
        for (let col = 0; col < ql2; col++){
          let fconfig = {...this.floating, width: l, length: w};
          var floating = new Floating(this.scene, fconfig);
          floating.controlMesh.position = new Vector3(y + w / 2, - this.floating.height / 2 + this.floating.aboveWater, x + l / 2);
          floating.resize(fconfig, floating.controlMesh.position);
          floatings.push({floating, config:fconfig});
          y += w;
        }
        x += l;
      }

    } else {
      totalWidth = qw1 * this.floating.width;
      totalLength = ql1 * this.floating.length + 2 * (this.floating.useLength2 ? this.floating.length2 : 0);

      let wrapperBox1 = MeshBuilder.CreateBox("wrapperBox", {height: 1, width: 1, depth: 1}, this.scene)
      wrapperBox1.material = new StandardMaterial("transparent", this.scene);
      wrapperBox1.material.alpha = 0;
      wrapperBox1.position = new Vector3(0, - this.floating.height / 2 + this.floating.aboveWater, 0);
      wrapperBox1.scaling = new Vector3(totalLength, this.floating.height, totalWidth);
      //floatingEdge1 = new FloatingEdge(this.scene, wrapperBox1, -1);
      //floatingEdge2 = new FloatingEdge(this.scene, wrapperBox1, 1);

      let rowMax = ql1 + (this.floating.useLength2 ? 2 : 0);
      let x = -totalLength / 2;
      for (let row = 0; row < rowMax; row++) {
        let y = -totalWidth / 2;
        let l = (row === 0 || row === rowMax-1) && this.floating.useLength2 ? this.floating.length2 : this.floating.length;
        let w = this.floating.width;
        for (let col = 0; col < qw1; col++){
          let fconfig = {...this.floating, width: w, length: l}
          var floating = new Floating(this.scene, fconfig);
          floating.controlMesh.position = new Vector3(x + l / 2, - this.floating.height / 2 + this.floating.aboveWater, y + w / 2);
          floating.resize(fconfig, floating.controlMesh.position);
          floatings.push({floating, config:fconfig});
          y += w;
        }
        x += l;
      }
    }

    this.floating.total = floatings.length;

    /* */
    var waterTexture = new Texture("/assets/textures/waterbump.png", this.scene);
    waterTexture.vScale = waterTexture.uScale = 200.0;
    var waterMaterial = new PBRMaterial("waterMaterial", this.scene);
    waterMaterial.backFaceCulling = false;
    waterMaterial.albedoTexture = waterTexture;
    waterMaterial.metallic = 0.1;
    waterMaterial.roughness = 0;
    waterMaterial.directIntensity = 1.0;
    waterMaterial.environmentIntensity = 0.1;
    waterMaterial.cameraExposure = 0.66;
    waterMaterial.cameraContrast = 1.66;
    waterMaterial.microSurface = 1;
    waterMaterial.reflectivityColor = new Color3(1.0, 1.0, 1.0);
    waterMaterial.alpha = 0.6;
    var water = Mesh.CreateGround("water", 2000, 2000, 32, this.scene, false);
    water.position = new Vector3(0, 0, 0);
    water.material = waterMaterial;

    var groundTexture = new Texture("/assets/textures/sand.jpg", this.scene);
    groundTexture.vScale = groundTexture.uScale = 200.0;
    var groundMaterial = new PBRMaterial("groundMaterial", this.scene);
    groundMaterial.albedoTexture = groundTexture;
    groundMaterial.metallic = 0;
    groundMaterial.roughness = 0;
    groundMaterial.reflectionColor = new Color3(0.2,0.2,0.2);
    var ground = Mesh.CreateGround("ground", 2000, 2000, 32, this.scene, false);
    ground.position = new Vector3(0, -50, 0);
    ground.material = groundMaterial;

    // Edges

    MeshBuilder.CreateBox("s1", {height: 50, width: 2000, depth: 10}, this.scene).position = new Vector3(0, -25, -1000);
    MeshBuilder.CreateBox("s2", {height: 50, width: 2000, depth: 10}, this.scene).position = new Vector3(0, -25, 1000);
    MeshBuilder.CreateBox("s3", {height: 50, width: 10, depth: 2000}, this.scene).position = new Vector3(-1000, -25, 0);
    MeshBuilder.CreateBox("s4", {height: 50, width: 10, depth: 2000}, this.scene).position = new Vector3(1000, -25, 0);

    /* */

    engine.runRenderLoop(() => {
      if (!this.scene) return;

      //if (camera.radius > 70)    camera.radius = 70;
      //if (camera.radius < 20)    camera.radius = 20;
      /*if (floatingEdge1.edge){
        floatingEdge1.edge.scaling = new Vector3(1, 1, 0.3 * 2.5 / totalLength);
      }
      if (floatingEdge2.edge){
        floatingEdge2.edge.scaling = new Vector3(1, 1, 0.3 * 2.5 / totalLength);
      }*/

      /*for (let f of floatings){
        f.floating.resize(f.config);
      }*/

      building.resize(this.building, building.controlMesh.position);
      this.scene.render();
    });

    window.addEventListener("resize", function () {
      try {
        engine.resize();
      } catch(e) {}
    });
  }


}
