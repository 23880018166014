import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-settings-page',
  templateUrl: './tech-settings-page.component.html',
  styleUrls: ['./tech-settings-page.component.scss']
})
export class TechSettingsPageComponent implements OnInit {

  public settings:any = {
    inactivity: 30,
    logExpiration: 180,
    archiveDays: 7,
    archiveHost: 'archive.ponton-terv.web.app'
  };

  constructor() { }

  ngOnInit() {
  }

  save(){}
}
